import React from 'react'

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ImageMapper from 'react-image-mapper';

import Layout from '../../components/layout'
import process from '../../images/ZepProzess.png'

class ProcessPage extends React.Component {

  state = {
    modal: false,
    modalTitle: '',
    modalText: '',
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  }

  handleProcessImageClick = (area) => {
    this.setState({
      modal: true,
      modalTitle: area.name,
      modalText: area.text,
    });
  }

  render() {

    return (
      <Layout>
        <section id="intro" className="section-wrapper">
          <header className="section-header">
            <h3>Der Prozess</h3>
            <h5>Von der Zeugnisbestellung bis zur Aushändigung</h5>
          </header>
          <p>
            Der dargestellte Prozessablauf beschreibt den ZEP Standard. Prozessanpassungen an Ihre firmenspezifische Anforderungen sind möglich.
            Für Detailinformationen klicken Sie bitte auf die einzelnen Prozessschritte.
          </p>
        </section>
        <div style={{ 'width': '960px', 'margin': '0 auto', position: 'relative' }}>
          <ImageMapper width="960" src={process} map={infoMap} onClick={this.handleProcessImageClick} fillColor="transparent" strokeColor="rgba(0,0,255,0.6)" onImageClick={this.close} />
        </div>
        <Modal isOpen={this.state.modal} fade={false} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}><div dangerouslySetInnerHTML={{ __html: this.state.modalTitle }} /></ModalHeader>
          <ModalBody><div dangerouslySetInnerHTML={{ __html: this.state.modalText }} /></ModalBody>
        </Modal>

      </Layout >
    )
  }
}

export default ProcessPage

const infoMap = {
  name: "my-map",
  areas: [
    {
      shape: "circle",
      coords: [40, 54, 15],
      name: 'Start',
      text: '<strong>Der Prozess beginnt</strong> <br /><br /> Der Start Ihres ZEP kann in Ihr HR-Portal integriert werden. „SingleSignOn“ ist im Standard enthalten und kann nach Bedarf aktiviert werden. <br /><br /> Der dargestellte Prozessablauf beschreibt den ZEP Standardprozess. Prozessanpassungen an Ihre firmenspezifische Anforderungen sind möglich.'
    },
    {
      shape: "rect",
      coords: [72, 36, 157, 77],
      name: 'Arbeitszeugnis bestellen',
      text: 'Bestellen Sie sich ein Zwischenzeugnis, ein Schlusszeugnis oder eine Arbeitsbestätigung.'
    },
    {
      shape: "circle",
      coords: [40, 190, 15],
      name: 'Start',
      text: '<strong>Der Prozess beginnt</strong> <br /><br /> Der Start Ihres ZEP kann in Ihr HR-Portal integriert werden. „SingleSignOn“ ist im Standard enthalten und kann nach Bedarf aktiviert werden. <br /><br /> Der dargestellte Prozessablauf beschreibt den ZEP Standardprozess. Prozessanpassungen an Ihre firmenspezifische Anforderungen sind möglich.'
    },
    {
      shape: "rect",
      coords: [72, 172, 157, 212],
      name: 'Arbeitszeugnis bestellen',
      text: 'Bestellen Sie sich ein Zwischenzeugnis, ein Schlusszeugnis oder eine Arbeitsbestätigung.'
    },
    {
      shape: "circle",
      coords: [40, 327, 15],
      name: 'Start',
      text: '<strong>Der Prozess beginnt</strong> <br /><br /> Der Start Ihres ZEP kann in Ihr HR-Portal integriert werden. „SingleSignOn“ ist im Standard enthalten und kann nach Bedarf aktiviert werden. <br /><br /> Der dargestellte Prozessablauf beschreibt den ZEP Standardprozess. Prozessanpassungen an Ihre firmenspezifische Anforderungen sind möglich.'
    },
    {
      shape: "rect",
      coords: [72, 305, 157, 345],
      name: 'Arbeitszeugnis bestellen',
      text: 'Bestellen Sie sich ein Zwischenzeugnis, ein Schlusszeugnis oder eine Arbeitsbestätigung.'
    },
    {
      shape: "rect",
      coords: [205, 145, 290, 185],
      name: 'Zeugnisinput erfassen',
      text: '<strong>Aufgaben/Spezialaufgaben erfassen</strong><br /> Erfassen Sie die Tätigkeiten und Aufgabengebiete Ihres Mitarbeitenden.<br /> Dieses Erfassen können Sie an den Mitarbeitenden delegieren oder Ihren Text zur Durchsicht, Ergänzung oder Korrektur weitergeben <br /><br /> <strong>Kompetenzen bewerten</strong><br /> Den Zeugnisinput erfassen Sie über die verschiedenen Kompetenzbereiche. Hier können Sie Zielerreichung, Aufgabenerfüllung, ... Ihres Mitarbeitenden bewerten.<br /> Kreuzen Sie die passenden Attribute an und aus über 2500 Textbausteinen wird ZEP die passende Textpassage (männlich, weiblich, Gegenwart, Vergangenheit) zusammensetzen.',
    },
    {
      shape: "rect",
      coords: [205, 208, 290, 246],
      name: 'Zeugnisinput erfassen (Stellvertreter)',
      text: 'Der Vorgesetzte kann die Erfassung des Zeugnisinputs an einen Stellvertreter delegieren.',
    },
    {
      shape: "rect",
      coords: [336, 280, 420, 322],
      name: 'Zeugnisvorschlag erstellen',
      text: 'Die Personalabteilung bearbeitet die Zeugnisvorschläge. Sie ergänzt, ändert und vervollständigt die Dokumente.'
    },
    {
      shape: "rect",
      coords: [336, 340, 420, 378],
      name: 'Zeugnisvorschlag prüfen (HRM)',
      text: 'Der Zeugnisvorschlag kann an eine weitere Prüfstelle wie beispielsweise ein HR-Manager weitergeschickt werden.'
    },
    {
      shape: "rect",
      coords: [450, 178, 535, 218],
      name: 'Gut zum Druck, OK erteilen',
      text: 'Sie erhalten von Ihrer Personalabteilung das "Gut zum Druck" des Zeugnisses und erteilen Ihr Einverständnis.'
    },
    {
      shape: "rect",
      coords: [693, 306, 778, 345],
      name: 'Arbeitszeugnis versenden',
      text: 'Sobald Sie als Personalabteilung das "Gut zum Druck" erhalten, können Sie das Zeugnis ausdrucken und an den zuständigen Vorgesetzten versenden.'
    },
    {
      shape: "rect",
      coords: [620, 178, 705, 218],
      name: 'Zeugnisvorschlag abnehmen',
      text: 'Die weitere Instanz wie beispielsweise der nächst höhere Vorgesetzte erhält von der Personalabteilung das "Gut zum Druck" des Zeugnisses und erteilen das Einverständnis.'
    },
    {
      shape: "rect",
      coords: [543, 306, 626, 345],
      name: 'Zeugnisvorschlag fertigstellen',
      text: 'Sobald Sie als Personalabteilung das "Gut zum Druck" vom Vorgesetzten erhalten, können Sie das Zeugnis prüfen und an eine weitere Instanz wie beispielsweise den nächst höheren Vorgesetzten schicken.'
    },
    {
      shape: "rect",
      coords: [693, 366, 778, 405],
      name: 'Zeugnis bearbeiten',
      text: 'Ein abgeschlossenes Zeugnis kann durch die Personalabteilung wieder eröffnet und bearbeitet werden.'
    },
    {
      shape: "rect",
      coords: [805, 306, 888, 345],
      name: 'Eingang Zeugnisdoppel bestätigen',
      text: 'Sie bestätigen den Eingang des Zeugnisdoppel in der Personalabteilung und schliessen mit diesem Arbeitsgang den Prozess ab.'
    },
    {
      shape: "circle",
      coords: [925, 326, 15],
      name: 'Ende',
      text: 'ZEP hat wieder einen Zeugnisprozess erfolgreich abgeschlossen. Als Benutzer von ZEP haben Sie jederzeit den Überblick über alle erstellten Zeugnisse, die Sie betreffen.'
    },
    {
      shape: "rect",
      coords: [205, 34, 290, 74],
      name: 'Aufgabe an MA delegieren',
      text: 'Falls der Vorgesetzte die Aufgaben des Mitarbeitenden nicht selbst erfassen will, kann dieser diese Aufgabe an Sie delegieren.'
    },
    {
      shape: "rect",
      coords: [380, 145, 455, 168],
      name: 'Zurückweisen',
      text: '… oder Sie weisen das "Gut zum Druck" mit Kommentar zurück'
    },
    {
      shape: "rect",
      coords: [450, 36, 535, 77],
      name: 'Weitergabe an Mitarbeitenden',
      text: 'Eventuell erhalten Sie von Ihrem Vorgesetzten noch einmal die Möglichkeit einen Kommentar zum "Gut zum Druck" abzugeben.'
    }
  ]
}